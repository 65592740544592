// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.ENVIRONMENT_TYPE !== 'development') {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: process.env.ENVIRONMENT_TYPE,
    });

    Sentry.setTag('instance', process.env.ENVIRONMENT_NAME);
}
