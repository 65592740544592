export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
export const GA_GTM_ID = process.env.NEXT_PUBLIC_GA_GTM_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
    window?.gtag && window.gtag('config', GA_TRACKING_ID as string, { page_path: url });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
    action,
    category,
    label,
    value,
}: {
    action: string;
    category: string;
    label: string;
    value: string;
}) => {
    window?.gtag &&
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        });
};
