declare global {
    // eslint-disable-next-line no-var
    var serviceWorker: ServiceWorker | null | undefined;
}

export function registerServiceWorker() {
    if (navigator && 'serviceWorker' in navigator && window) {
        window.addEventListener('load', async () => {
            try {
                const registration = await navigator.serviceWorker.register('/serviceworker.js');
                global.serviceWorker = registration.active;

                // eslint-disable-next-line no-console
                console.log('Service Worker registration successful with scope: ', registration.scope);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn('Service Worker registration failed: ', error);
            }
        });
    }
}
