import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import activities from './activities.json';
import animals from './animals.json';
import foodDrink from './foodDrink.json';
import objects from './objects.json';
import peopleBody from './peopleBody.json';
import smileysEmotion from './smileysEmotion.json';
import symbols from './symbols.json';
import travelPlaces from './travelPlaces.json';

export const EmojiGroups = {
    SMILEYS_EMOTION: 'smileysEmotion',
    ACTIVITIES: 'activities',
    ANIMALS: 'animals',
    FOOD_DRINK: 'foodDrink',
    OBJECTS: 'objects',
    PEOPLE_BODY: 'peopleBody',
    SYMBOLS: 'symbols',
    TRAVEL_PLACES: 'travelPlaces',
} as const;

export const EmojiTotalGroups = Object.keys(EmojiGroups).length;

export type EmojiGroupTypes = typeof EmojiGroups[keyof typeof EmojiGroups];

export interface NameUnicode {
    unicode: string;
}

export interface EmojiData extends NameUnicode {
    tags: string[];
}

export interface EmojiListData {
    emojiList: EmojiData[];
    group: number;
}

export const allEmojiGroupsData: { [key in EmojiGroupTypes]: EmojiListData } = {
    animals,
    activities,
    foodDrink,
    objects,
    peopleBody,
    smileysEmotion,
    symbols,
    travelPlaces,
};

type RecentEmojiContextType = {
    recent: EmojiData[];
    addToRecent: (emoji: EmojiData) => void;
};

const RecentEmojiContext = createContext<RecentEmojiContextType | null>(null);

export function RecentEmojiProvider({ children }: PropsWithChildren<unknown>) {
    const [recent, setRecent] = useState<EmojiData[]>([]);

    useEffect(() => {
        try {
            if (window && window.localStorage) {
                const jsonString = window.localStorage.getItem('recent-used-emojis');
                if (jsonString) {
                    const array = JSON.parse(jsonString) as EmojiData[];
                    setRecent(array);
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('could not load recent emojis from localStorage.', error);
        }
    }, []);

    const addToRecent = useCallback((emoji: EmojiData) => {
        setRecent((prev) => {
            const newList = [emoji, ...prev.filter((existing) => emoji !== existing).slice(0, 6)];

            try {
                if (window && window.localStorage) {
                    const jsonString = JSON.stringify(newList);
                    window.localStorage.setItem('recent-used-emojis', jsonString);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn('could not save recent emojis to localStorage.', error);
            }

            return newList;
        });
    }, []);

    const providerValue = useMemo(
        () => ({
            addToRecent,
            recent,
        }),
        [addToRecent, recent],
    );

    return <RecentEmojiContext.Provider value={providerValue}>{children}</RecentEmojiContext.Provider>;
}

export const useRecentEmojis = () => useContext(RecentEmojiContext) as RecentEmojiContextType;
