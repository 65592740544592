import { extendTheme, ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
};

const theme = extendTheme({
    config,
    fonts: {
        heading: 'Montserrat,Inter,sans-serif',
        body: 'Lato,Inter,sans-serif',
    },
    colors: {
        blackWhite: {
            '50': '#000000',
            '100': '#000000',
            '200': '#000000',
            '300': '#000000',
            '400': '#000000',
            '500': '#000000',
            '600': '#000000',
            '700': '#000000',
            '800': '#000000',
            '900': '#000000',
        },
    },
    styles: {
        global: {
            h2: {
                fontSize: '4xl',
                fontWeight: 'bold',
            },
            h3: {
                fontSize: '2xl',
            },
            h4: {
                fontSize: 'xl',
            },
        },
    },
    components: {
        FormLabel: {
            baseStyle: {
                fontWeight: 700,
            },
        },
    },
});

export default theme;
