import { ChakraProvider } from '@chakra-ui/react';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import ProgressBar from '@badrap/bar-of-progress';
import Router, { useRouter } from 'next/router';

import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/400-italic.css';
import 'cropperjs/dist/cropper.css';
import 'react-calendar/dist/Calendar.css';
import 'focus-visible/dist/focus-visible';
import 'components/DatePicker/date-picker.css';
import 'lib/editorjsPlugins/simple-image/index.css';
import 'styles/global.css';
import 'styles/editor.css';

import theme from 'lib/theme';
import { DEFAULT_ROADMAP_GRADIENT } from 'lib/constants';
import Script from 'next/script';
import * as gtag from 'lib/gtag';
import { useEffect } from 'react';
import { RecentEmojiProvider } from 'lib/emoji';
import * as Sentry from '@sentry/nextjs';
import { registerServiceWorker } from 'lib/serviceWorker';
import { SidePanelProvider } from 'components/Timeline/SidePanel/SidePanel';

const progress = new ProgressBar({
    size: 2,
    color: DEFAULT_ROADMAP_GRADIENT.green,
    className: 'page-load-progress',
    delay: 100,
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

function renderGoogleAnalytics() {
    return (
        <>
            {gtag.GA_TRACKING_ID && (
                <>
                    <Script
                        strategy="afterInteractive"
                        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                    />
                    <Script
                        id="gtag-init"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${gtag.GA_TRACKING_ID}', {
                            page_path: window.location.pathname,
                            });
                        `,
                        }}
                    />
                </>
            )}
            {gtag.GA_GTM_ID && (
                <>
                    <Script
                        id="gtm"
                        strategy="afterInteractive"
                        src={`https://www.googletagmanager.com/gtm.js?id=${gtag.GA_GTM_ID}`}
                    />
                    <Script
                        id="gtm-init"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                        `,
                        }}
                    />
                    <noscript>
                        <iframe
                            src={`https://www.googletagmanager.com/ns.html?id=${gtag.GA_GTM_ID}`}
                            height="0"
                            width="0"
                            style={{ display: 'none', visibility: 'hidden' }}
                        />
                    </noscript>
                </>
            )}
        </>
    );
}

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            gtag.pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        registerServiceWorker();
    }, []);

    return (
        <>
            {renderGoogleAnalytics()}
            <Sentry.ErrorBoundary fallback={<p>An error has occured</p>}>
                <ChakraProvider theme={theme}>
                    <SessionProvider session={pageProps.session}>
                        <RecentEmojiProvider>
                            <SidePanelProvider>
                                <Component {...pageProps} />
                            </SidePanelProvider>
                        </RecentEmojiProvider>
                    </SessionProvider>
                </ChakraProvider>
            </Sentry.ErrorBoundary>
        </>
    );
}
export default MyApp;
