import { IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { CloseIcon } from '@chakra-ui/icons';
import { useColorModeValue } from '@chakra-ui/react';
import { MotionBox } from 'components/Motion';
import { noop } from 'lodash';
import {
    createContext,
    PropsWithChildren,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

const PANEL_SIZE = '370px';

export default function SidePanel() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [panel, setPanel] = useState<ReactElement | null>(null);

    const bgColor = useColorModeValue('white', 'gray.600');
    const borderColor = useColorModeValue('gray.100', 'gray.800');

    const context = useSidePanel();

    const handleClose = useCallback(() => {
        onClose();
        setTimeout(() => setPanel(null), 500);
    }, [onClose]);

    const openPanel = useCallback(
        (panel: ReactElement) => {
            if (panel === null) {
                setPanel(panel);
                onOpen();
            } else {
                handleClose();
                setTimeout(() => {
                    setPanel(panel);
                    onOpen();
                }, 500);
            }
        },
        [handleClose, onOpen],
    );

    useEffect(() => {
        context?.setOpenPanelFunction(() => openPanel);
        context?.setClosePanelFunction(() => handleClose);
    }, [context, handleClose, openPanel]);

    if (!context) {
        return null;
    }

    return (
        <MotionBox
            layout
            direction="column"
            width={PANEL_SIZE}
            height="100%"
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            bgColor={bgColor}
            borderColor={borderColor}
            borderWidth="1px"
            boxShadow="lg"
            mr={isOpen ? 0 : `-${PANEL_SIZE}`}
            overflowY="auto"
            overflowX="hidden"
        >
            {panel}
            <IconButton
                aria-label="Close panel"
                variant="ghost"
                icon={<CloseIcon />}
                position="absolute"
                right={2}
                top={2}
                size="sm"
                onClick={handleClose}
            />
        </MotionBox>
    );
}

type OpenPanelFunction = (panel: ReactElement) => void;

type SidePanelContextType = {
    openPanel: OpenPanelFunction;
    closePanel: () => void;
    setOpenPanelFunction: (func: OpenPanelFunction) => void;
    setClosePanelFunction: (func: () => void) => void;
};

const SidePanelContext = createContext<SidePanelContextType | null>(null);

export function SidePanelProvider({ children }: PropsWithChildren<unknown>) {
    const [openPanel, setOpenPanelFunction] = useState(() => noop);
    const [closePanel, setClosePanelFunction] = useState(() => noop);

    const providerValue = useMemo(
        () => ({
            openPanel,
            closePanel,
            setOpenPanelFunction,
            setClosePanelFunction,
        }),
        [closePanel, openPanel],
    );

    return <SidePanelContext.Provider value={providerValue}>{children}</SidePanelContext.Provider>;
}

export function useSidePanel() {
    return useContext(SidePanelContext);
}
