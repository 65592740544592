export const LOCAL_API_DATE_FORMAT = 'yyyy-MM-dd';
export const LOCAL_CLIENT_DATE_FORMAT = 'dd MMM yyyy';
export const DEFAULT_TIMEZONE = 'UTC';

export const DEFAULT_ROADMAP_GRADIENT = { start: '#FE1E61', end: '#CD0FE8', green: '#48D1CC' };

export const ADDON_KEY = 'goosly';
export const ADDON_NAME = 'Goosly';
export const PLAN_KEY = `plan-${ADDON_KEY}`;
export const PLAN_EXPIRES = `plan-expires-${ADDON_KEY}`;

export const DEFAULT_UNGROUPED_COLOR = '#67269F';

export const COLORS = [
    '#FFFFFF',
    '#000000',
    '#A0AEC0',
    '#E53E3E',
    '#ED8936',
    '#F6E05E',
    '#38A169',
    '#00B5D8',
    '#9F7AEA',
    '#ED64A6',
];

export const SKIP_TERMLY_ON_PAGES = ['/embed', '/thumb'];
